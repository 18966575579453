<header>
  <nav>
    <a routerLink="/planos" class="logo-link">
      <img src="../assets/logos/logo2.png" class="itc-navbar-logo" alt="ITC Logo">
    </a>
    <div id="nav-links" class="nav-links">
      <ul>
        <li class="navbar-button"><a href="https://itcnet.com.br/index.php">ITC</a></li>
        <li *ngIf="!isLoggedIn" class="navbar-button">
          <a (click)="redirectToLoginPage(); closeMenu()">Login</a>
        </li>
        <li *ngIf="!isLoggedIn" class="navbar-primary-button">
          <a (click)="redirectToRegisterPage(); closeMenu()">Inscrever-se</a>
        </li>
        <li *ngIf="isLoggedIn" class="navbar-button">
          <a (click)="redirectToAccount(); closeMenu()">Conta</a>
        </li>
        <li *ngIf="isLoggedIn" class="navbar-primary-button">
          <a (click)="redirectToLogout(); closeMenu()">Sair</a>
        </li>
      </ul>
    </div>
    <button class="hamburger" (click)="toggleMenu()">&#9776;</button>
  </nav>
</header>

<router-outlet></router-outlet>

<app-whatsapp-contact></app-whatsapp-contact>

<footer class="main-footer">
  <div>
    <span>© 1994 - 2024 - ITC Informativo Tributário Contábil.</span>
    <span>Todos os direitos reservados.</span>
  </div>
</footer>