import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthenticationService } from '../services/authentication/authentication.service';

export const AuthGuard: CanActivateFn = (): Observable<boolean> => {
  const authenticationService = inject(AuthenticationService);

  return from(authenticationService.getToken()).pipe(
    map(token => {
      if (token && !authenticationService.isTokenExpired()) {

        return true;
      } else {
        authenticationService.showAuthenticationMessage();
        authenticationService.redirectToLoginPage();
        return false;
      }
    }),
    catchError(error => {
      console.log(error);
      authenticationService.showAuthenticationMessage();
      authenticationService.redirectToLoginPage();
      return of(false);
    })
  );
};
