import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const keycloakHttpInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  // Ensure inject() is used within an Angular context
  const keycloakService = inject(KeycloakService);

  return from(keycloakService.updateToken(30)).pipe(
    switchMap(() => from(keycloakService.getToken())),
    switchMap(token => {
      const headers = req.headers
        .set('Authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json');

      const clonedRequest = req.clone({ headers });
      return next(clonedRequest);
    })
  );
};
